import { Button, Col } from "react-bootstrap"
import { ITableComponentCols, TableComponent } from "../../../components/Table/TableComponent"
import { IListMeetingWeekResponse } from "../../../interfaces/meetingWeek/meetingWeek.interface"
import { getDateLocale } from "../../../utils/Helper"
import { Link, useParams } from "react-router-dom"
import { TableCustomComponent } from "../../../components/Table"
import { IMeta } from "../../../interfaces/generic/generic.interface"
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent"
import useGuard from "../../../contexts/Guard"
import { useState } from "react"
import { ModalComponent } from "../../../components/ModalComponent"
import { deleteMeetingWeek } from "../../../api/meetingWeek/meetingWeek"

interface IWeekMeetingList {
    data: any[]
    meta: IMeta
    callbackPagination: (params: IPaginationsCallback) => void
    callbackReaload: () => void
}

export const ListWeekMeeting: React.FC<IWeekMeetingList> = ({
    data,
    meta,
    callbackPagination,
    callbackReaload
}) => {

    const { groupId } = useParams()
    const [showModalRecorrenceDelete, setShowModalRecorrenceDelete] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [showRowSelected, setShowRowSelected] = useState<IListMeetingWeekResponse>({} as IListMeetingWeekResponse)

    const handleOpenModalRemove = (row: IListMeetingWeekResponse) => {
        setShowRowSelected(row)
        if (row.is_recorrence) {
            handleOpenModalDeleteRecorrence()
        } else {
            handleOpenModalDelete()
        }
    }


    const handleDeleteWeekMeeting = async (deleteAll: boolean) => {
        try {
            await deleteMeetingWeek({
                id_group: +groupId!,
                id_meeting_week: showRowSelected?.id_meeting_week,
                is_recorrence: deleteAll
            })
            handleCloseModalDeleteRecorrence()
            handleCloseModalDelete()
            callbackReaload()
        } catch (error) {
            console.log(error)
        }
    }

    const handleOpenModalDeleteRecorrence = () => {
        setShowModalRecorrenceDelete(true)
    }

    const handleCloseModalDeleteRecorrence = () => {
        setShowModalRecorrenceDelete(false)
    }

    const handleOpenModalDelete = () => {
        setShowModalDelete(true)
    }

    const handleCloseModalDelete = () => {
        setShowModalDelete(false)
    }

    const { CheckPermissionWithArray } = useGuard()
    const columns: ITableComponentCols[] = [
        {
            title: 'Data',
            render: (row: IListMeetingWeekResponse) => (
                <span>{getDateLocale(row.date, 'pt-BR')}</span>
            )
        },
        {
            title: 'Membros presentes',
            render: (row: IListMeetingWeekResponse) => (
                <span>{row.present_users}</span>
            )
        },
        {
            title: 'Convidados presentes',
            render: (row: IListMeetingWeekResponse) => (
                <span>{row.guests_present}</span>
            )
        },
        {
            title: 'Ações',
            permissions: ['weekly-meetings.presence'],
            render: (row: IListMeetingWeekResponse) => (
                <>
                    {row.disabled ? (
                        <Link to={`presence/week-meeting/${row.id_meeting_week}`}>
                            <Button className="button-show" variant="primary">Ver</Button>
                        </Link>
                    ) : (
                        <Link to={`presence/week-meeting/${row.id_meeting_week}`}>
                            <Button variant="primary">Presença</Button>
                        </Link>
                    )}
                    {(CheckPermissionWithArray(['meeting_week.delete']) && !row.disabled) && (
                        <Button
                            className="mr-1 m-1"
                            variant="danger"
                            onClick={() => handleOpenModalRemove(row)}
                        >
                            Deletar
                        </Button>
                    )}
                </>
            )
        }
    ]

    return (
        <>
            <TableCustomComponent.Root>
                <Col>
                    <TableCustomComponent.Table
                        cols={columns}
                        data={data}
                        striped={true}
                    />
                </Col>
                <Col>
                    <TableCustomComponent.Pagination
                        callback={callbackPagination}
                        {...meta}
                    />
                </Col>
            </TableCustomComponent.Root>
            {showModalRecorrenceDelete && (
                <ModalComponent.Root
                    show={showModalRecorrenceDelete}
                    onHide={handleCloseModalDeleteRecorrence}
                    size="lg"                    
                >
                    <ModalComponent.Content>
                        <p className="modal_delete_recorrence--title">Deseja apagar as recorrências ?</p>
                    </ModalComponent.Content>

                    <div className="modal_delete_recorrence__actions">
                        <ModalComponent.Actions
                            variant="primary"
                            onClick={() => handleCloseModalDeleteRecorrence()}
                            className="w-25"
                        >
                            Cancelar
                        </ModalComponent.Actions>
                        <ModalComponent.Actions
                            variant="danger"
                            onClick={() => handleDeleteWeekMeeting(false)}
                            className="w-25"
                        >
                            Apagar somente essa
                        </ModalComponent.Actions>
                        <ModalComponent.Actions
                            variant="danger"
                            onClick={() => handleDeleteWeekMeeting(true)}
                            className="w-25"
                        >
                            Apagar tudo
                        </ModalComponent.Actions>
                    </div>

                </ModalComponent.Root>
            )}
            {showModalDelete && (
                <ModalComponent.Root
                    show={showModalDelete}
                    onHide={handleCloseModalDelete}
                    size="lg"                    
                >
                    <ModalComponent.Content>
                        <p className="modal_delete_recorrence--title">Deseja realmente apagar a reunião semanal ?</p>
                    </ModalComponent.Content>

                    <div className="modal_delete_recorrence__actions">
                        <ModalComponent.Actions
                            variant="primary"
                            onClick={() => handleCloseModalDelete()}
                            className="w-25"
                        >
                            Cancelar
                        </ModalComponent.Actions>
                        <ModalComponent.Actions
                            variant="danger"
                            onClick={() => handleDeleteWeekMeeting(false)}
                            className="w-25"
                        >
                            Apagar
                        </ModalComponent.Actions>                        
                    </div>

                </ModalComponent.Root>
            )}
        </>
    )
}
import './createWeekMeeting.css'
import { Button, Form, FormCheck } from "react-bootstrap"
import useGuard from "../../../contexts/Guard"
import { FormCustom } from "../../../components/Form"
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

interface ICreateWeekMeetingProps {
    goBack: () => void
}

export const CreateWeekMeeting: React.FC<ICreateWeekMeetingProps> = ({
    goBack
}) => {
    const { infoUser } = useGuard()
    const { register, watch, setValue } = useFormContext()
    const { t } = useTranslation()

    const isCurrent = watch('is_recurrent')

    useEffect(() => {
        if (!isCurrent) {
            setValue('meeting_end', null)
        }
    }, [isCurrent])

    return (
        <div>
            <h3 className='title-group-week'>{infoUser.group_selected.name}</h3>
            <Form.Group className="mb-3 w-25">
                <Form.Label htmlFor="meeting_day">{t('data')}</Form.Label>
                <FormCustom.Control 
                    field="meeting_day"
                    id="meeting_day"
                    type="date"
                />                
                <FormCustom.ErrorMessage field="meeting_day" />
            </Form.Group>
            {isCurrent && (
                <Form.Group className="mb-3 w-25">
                <Form.Label htmlFor="meeting_end">{t('dateEnd')}</Form.Label>
                <FormCustom.Control 
                    field="meeting_end"
                    id="meeting_end"
                    type="date"
                />                
                <FormCustom.ErrorMessage field="meeting_end" />
            </Form.Group>
            )}
            <Form.Group>
                <Form.Label>{t('recurring')}: </Form.Label>
                <FormCheck type='switch' {...register('is_recurrent')}  />
            </Form.Group>
            <Button 
                type="submit"
                variant="primary"
                className="mb-3 mr-1 m-1 button-week-submit"                
            >Criar</Button>

            <p className='alert-week'><span>Importante:</span> Ao criar a reunião, você poderá acessa-lá em reuniões semanais ou clicando <span onClick={goBack} className='goback'>aqui</span></p>
        </div>
    )
}
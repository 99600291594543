import { toast } from "react-toastify"
import { ISendToast } from "../interfaces/toast/ISendToast"
import { ILocalAvalible } from "../interfaces/generic/generic.interface"
import { IAuthUserPayload } from "../interfaces/user/IUser"
import { RoleEnum } from "../interfaces/enum/roles.enum"
import { COLOR } from "../enum/color.enum"

export const dateFormat = (date: string): string => {
    return new Date(date).toLocaleDateString('pt-BR')
}

export const getEnumKeyByValue = (enumObj: any, value: number): string | undefined => {
    return Object.keys(enumObj).find(key => enumObj[key] === value);
}

export const dateTimeFormat = (date: string): string => {
    let newDate = new Date(date)
    let time = ("0" + newDate.getHours()).slice(-2) + ":" + ("0" + newDate.getMinutes()).slice(-2)
    let dateFormated = newDate.toLocaleDateString('pt-BR')
    return `${dateFormated} ${time}`
}

export const catchToast = (error: any): void => {
    toast.error(error.response.data.error)
}

export const sendToast = ({ message, type }: ISendToast): void => {
    if (type === 'error') {
        toast.error(message)
    }
    if (type === 'success') {
        toast.success(message)
    }
    if (type === 'info') {
        toast.success(message)
    }
}

export const getLang = (langs: any[], lang: string) => {
    return langs.find((langList) => {
        return langList.value === lang
    })
}

export const getGroups = (groups: []) => {
    var arr: any = []
    groups.forEach((group: any) => {
        arr.push({ label: group.name, value: group.id })
    })
    return arr
}

export const blobToBase64 = (blob: File) => {
    if (!blob) return
    return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
    })
}

export const clearBase64 = (base64: string): string => {
    return base64.toString().replace(/^data:(.*,)?/, '')
}

export const checkObjectIsEmpty = (obj: any) => {
    return obj && Object.keys(obj).length > 0 ? false : true
}

export const checkIfKeyExistsInArray = (arr: [], data: any, key: string) => {
    let checkIfExists = arr.filter((row: any) => {
        return row[key] === data[key]
    })
    return checkIfExists.length > 0
}

export const getKeys = (obj: any) => {
    return Object.keys(obj.at())
}

export const titleCase = (string: string) => {
    return string[0].toUpperCase() + string.slice(1).toLowerCase()
}

export const resolveUrlWithMeta = (string: string, meta: string) => {
    if (meta === null || meta === '' || meta === undefined) {
        return string
    }
    return `${string}/?${meta}`
}

export const resolveOptionsSelect = (obj: any) => {
    return obj.map((row: any) => {
        return {
            value: row.id,
            label: row.name
        }
    });
}

export const normalizeCpf = (cpf: string): string => {
    if (!cpf) return ''

    let value = cpf
    value = value.replace(/\D/g, "")
    value = value.replace(/(\d{3})(\d)/, "$1.$2")
    value = value.replace(/(\d{3})(\d)/, "$1.$2")
    value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    value = value.substring(0, 14)
    return value
}

export const getOnlyNumberOfCpf = (cpf: string): string => {
    let value = cpf.toString().replace(/\.|-/gm, '')
    return value
}

export const getOnlyNumberOfCpfOrCnpj = (cpf: string): string => {
    let value = cpf.toString().replace(/\.|-|\//gm, '')
    return value
}

export const getOnlyNumberPhone = (phone: string): string => {
    return phone.replace(/[^0-9]+/g, '')
}

export const phoneMask = (value: string) => {
    if (!value) return ""
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d)/, "($1) $2")
    value = value.replace(/(\d)(\d{4})$/, "$1-$2")
    value = value.substring(0, 15)
    return value
}

export const validateCPf = (cpf: string): boolean => {
    const cpfWithoutMask = cpf.replace(/\D/g, '')
    let fistDigitValid = false
    let segundDigitValid = false

    for (let i = 0; i <= 9; i++) {
        if (String(i).repeat(11) === cpfWithoutMask) return false
    }

    let total = 0

    //fist validate
    for (let i = 1; i <= 9; i++) {
        total = total + Number(cpfWithoutMask.substring(i - 1, i)) * (11 - i)
    }

    let fisrtDigit = (total * 10) % 11
    if (fisrtDigit === 10 || fisrtDigit == 11) fisrtDigit = 0

    if (fisrtDigit === +cpfWithoutMask.substring(9, 10)) fistDigitValid = true
    total = 0

    //segund validate
    for (let i = 1; i <= 10; i++) {
        total = total + Number(cpfWithoutMask.substring(i - 1, i)) * (12 - i)
    }

    let segundDigit = (total * 10) % 11
    if (segundDigit === 10 || segundDigit == 11) segundDigit = 0
    if (segundDigit === +cpfWithoutMask.substring(10, 11)) segundDigitValid = true

    return fistDigitValid && segundDigitValid
}

export const isValidCnpj = (cnpj: string): boolean => {
    const cnpjWithoutMask = cnpj.replace(/\D/g, '')

    if (cnpjWithoutMask === '') return false

    if (cnpjWithoutMask.length !== 14) return false

    if (/^(\d)\1+$/.test(cnpjWithoutMask)) return false

    let t = cnpjWithoutMask.length - 2
    let d = cnpjWithoutMask.substring(t)
    let d1 = Number(d.charAt(0))
    let d2 = Number(d.charAt(1))
    let calc = (x: number) => {
        let n = cnpjWithoutMask.substring(0, x)
        let y = x - 7
        let s = 0
        let r = 0

        for (let i = x; i >= 1; i--) {
            s += Number(n.charAt(x - i)) * y--
            if (y < 2) y = 9
        }

        r = 11 - (s % 11)
        return r > 9 ? 0 : r
    }

    return calc(t) === d1 && calc(t + 1) === d2
}

export const maskCnpj = (cnpj: string): string => {
    if (!cnpj) return ''
    const cnpjmask = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    return cnpjmask.substring(0, 18)
}

export const zipCodeMask = (value: string) => {
    if (!value) return ""
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{5})(\d)/, '$1-$2')
    return value.substring(0, 9)
}

export const getOnlyNumberCep = (cep: string): string => {
    return cep.replace(/[^0-9]+/g, '')
}

export const getOnlyNumberOfCnpj = (cnpj: string): string => {
    return cnpj.replace(/[^0-9]+/g, '')
}

export const getDateLocale = (inputDate: string, locale: ILocalAvalible) => {

    if (!inputDate) return ''

    const date = new Date(inputDate);

    return Intl.DateTimeFormat(locale, { timeZone: 'UTC' }).format(date);
}

export const getDateLocaleUTC = (inputDate: string, locale: string) => {
    if (!inputDate) return '';

    const date = new Date(inputDate);

    // Formata a data considerando o fuso horário UTC
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC'
    };

    return date.toLocaleDateString(locale, options);
}


export const formattedDate = (x: Date, y: string) => {

    if (!x) return ''
    console.log(x, y)
    const z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds()
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v: string) {
        return ((v.length > 1 ? "0" : "") + z[v.slice(-1) as keyof typeof z]).slice(-2)
    });

    return y.replace(/(y+)/g, function (v) {
        return x.getFullYear().toString().slice(-v.length)
    });


}

export const tranformeValideStringDate = (date: Date) => {
    if (!date) return ''

    return new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit", timeZone: 'UTC' }).format(date.valueOf())
}

export const maskCpf_Cnpj = (value: string) => {
    if (!value) return ""
    let v = value.replace(/\D/g, "")

    if (v.length <= 11) {
        v = normalizeCpf(v)
    } else {
        v = v.replace(/^(\d{2})(\d)/, "$1.$2")
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
        v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
        v = v.replace(/(\d{4})(\d)/, "$1-$2")
    }

    return v.substring(0, 18)
}

export const blockContributionDate = (date: Date): boolean => {
    const currentDate = new Date()
    const dateToCompare = new Date(date)

    currentDate.setDate(currentDate.getDate() - 30)

    if (dateToCompare < currentDate) {
        return false
    }
    return true
}

export const isCurrency = (value: number | string): number | string => {
    if (!value) return ''

    if (typeof value === 'number') return value
    if (typeof value === 'string') return getCurrency(+value)
    return ''
}

export const getCurrency = (value: number): string => {
    const defaultValue = 0

    if (!value) {
        return defaultValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }
    return value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

export const getCurrentYear = () => {
    return new Date().getFullYear()
}

export const getCurrentMonth = () => {
    return new Date().getMonth() + 1
}

export const getCurrentMonthName = (local: string = 'pt-br') => {
    return new Date().toLocaleString(local, { month: 'long' }).replace(/^\w/, (c) => c.toUpperCase())
}

export const downloadImage = (blob: any, fileName: string) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.setAttribute('style', 'display:none;');
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
};

export const getHexdecimalColor = (score: number) => {
    if ((score >= 0 && score <=25) || score < 0) return COLOR.SILVER
    if (score >= 26 && score <=35) return COLOR.RED
    if (score >= 36 && score <=55) return COLOR.YELLOW
    if (score >= 56 && score <=75) return COLOR.BLUE
    if (score >= 76) return COLOR.GREEN

}

export const userAdmin = (): boolean => {
    const storage: IAuthUserPayload = JSON.parse(localStorage.getItem('@App:user')!)
    
    if (storage.role_selected.key === 'ADMIN') {
        return true
    }
    return false
}

export const userAdminOrDirector = (): boolean => {
    const storage: IAuthUserPayload = JSON.parse(localStorage.getItem('@App:user')!)
    
    return storage.role_selected.key.toUpperCase() === RoleEnum.ADMIN || storage.role_selected.key.toUpperCase() === RoleEnum.REGIONAL_DIRECTOR
}

export const getAllMonthsForSelect = () => {
    return [
        { value: 1, label: 'Janeiro' },
        { value: 2, label: 'Fevereiro' },
        { value: 3, label: 'Março' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Maio' },
        { value: 6, label: 'Junho' },
        { value: 7, label: 'Julho' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Setembro' },
        { value: 10, label: 'Outubro' },
        { value: 11, label: 'Novembro' },
        { value: 12, label: 'Dezembro' },
    ]
}

export const getYearOfRegisterUserLogged = () => {
    const storage: IAuthUserPayload = JSON.parse(localStorage.getItem('@App:user')!)
    const yearRegister = +storage.user.register_date.split('/')[2]
    const currentYear = getCurrentYear()
    const yearOptions: number[] = []

    for (let i = yearRegister; i <= currentYear; i++) {
        yearOptions.push(i)
    }

    return yearOptions
}

export const downloadAndConvertBufferToBlob = (file: string, filename: string) => {
    const blob = new Blob([file], {type: 'text/csv'})
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = `${filename}.csv`
    document.body.appendChild(link)
    link.click() 
    window.URL.revokeObjectURL(url)
    document.body.removeChild(link)
}
import { IResponseGeneric } from '../../interfaces/generic/generic.interface'
import { IDeleteMeetingWeek, IGeMeetingWeekListParams, IListMeetingWeekResponse, IShowPresenceParams, IShowPresenceResponse, IStoreMeetingWeek, IStoreMeetingWeekPresence, IUpdateWeekMeetingPresence } from '../../interfaces/meetingWeek/meetingWeek.interface'
import api from '../Api'

export const storeMeetingWeek = async (payload: IStoreMeetingWeek) => {
    return await api.post(`/meeting-week`, { ...payload })
}

export const deleteMeetingWeek = async (payload: IDeleteMeetingWeek) => {
    return await api.post(`/meeting-week/group/${payload.id_group}/meeting-week/${payload.id_meeting_week}`, { 
        is_recorrence: payload.is_recorrence
     })
}

export const getMeetingWeekList = async ({ id_group, params }: IGeMeetingWeekListParams) => {
    return await api.get<IResponseGeneric<IListMeetingWeekResponse>>(`/meeting-week/group/${id_group}${params}`)
}

export const getMeetingWeekPresence = async ({ id_group, meeting_week_id }:IShowPresenceParams) => {
    return await api.get<IShowPresenceResponse>(`/meeting-week/group/${id_group}/meeting-week/${meeting_week_id}`)
}

export const storeMeetingWeekPresence = async (payload: IStoreMeetingWeekPresence) => {
    return await api.post(`/meeting-week/presences`, { ...payload })
}

export const updateMeetingWeekPresence = async (payload: IUpdateWeekMeetingPresence) => {
    return await api.post(`/meeting-week/update-presences`, {...payload})
}
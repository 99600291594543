import './weeklyMeeting.css'
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap"
import { FormProvider, useForm } from "react-hook-form"
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { ListWeekMeeting } from "./components/ListWeekMeeting"
import { CreateWeekMeeting } from "./components/CreateWeekMeeting"
import { getMeetingWeekList, storeMeetingWeek } from "../../api/meetingWeek/meetingWeek"
import { IListMeetingWeekResponse } from "../../interfaces/meetingWeek/meetingWeek.interface"
import { blockContributionDate, getEnumKeyByValue, tranformeValideStringDate } from "../../utils/Helper"
import { searchFactory } from "../../factory/searchFactory"
import { IResponseGeneric } from "../../interfaces/generic/generic.interface"
import { IPaginationsCallback } from "../../components/Table/PaginationComponent"
import useGuard from "../../contexts/Guard"
import { DayWeekEnum } from "../../interfaces/enum/day_week.enum"
import { ModalComponent } from "../../components/ModalComponent"
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

const createMeetingWeekSchema = z.object({
    meeting_day: z.coerce.date().refine((data) => blockContributionDate(data), { message: 'Não é possível criar reuniões com mais de 1 mês de atraso!' }),
    meeting_end: z.coerce.date().optional().nullable().transform((str) => str ? new Date(str) : null),
    id_group: z.coerce.number({ required_error: 'grupo é obrigatório' }).positive(),
    is_recurrent: z.boolean().optional().default(false)
}).superRefine((data, ctx) => {
    if (data.is_recurrent && !data.meeting_end) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Data final é obrigatória para reuniões recorrentes',
            path: ['meeting_end']
        })
    }
    if ((data.meeting_end && data.meeting_end <= data.meeting_day) && data.is_recurrent) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Data final deve ser maior que a data inicial',
            path: ['meeting_end']
        })
    }
    if (data.meeting_end && data.meeting_end > new Date(data.meeting_day.getTime() + 365 * 24 * 60 * 60 * 1000)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Data final não pode ser maior que 1 ano a partir da data inicial',
            path: ['meeting_end']
        });
    }
})

export type CreateNeetingWeekFormData = z.infer<typeof createMeetingWeekSchema>

const WeeklyMeetings: React.FC = () => {

    const { groupId } = useParams()
    const { t } = useTranslation();

    const { CheckPermissionWithArray, infoUser } = useGuard()
    const [showModal, setShowModal] = useState(false);
    const [weekSelected, setWeekSelected] = useState('')


    const [activeTab, setActiveTab] = useState<number>(0);
    const [meetingWeeks, setMeetingWeeks] = useState<IResponseGeneric<IListMeetingWeekResponse>>(
        {} as IResponseGeneric<IListMeetingWeekResponse>
    )

    const createWeekMeetingForm = useForm<CreateNeetingWeekFormData>({
        resolver: zodResolver(createMeetingWeekSchema),
        defaultValues: {
            id_group: +groupId!
        }
    })

    const handleGoBack = () => {
        setActiveTab(0)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const {
        handleSubmit,
        reset
    } = createWeekMeetingForm

    const handleOpenModal = () => {
        setShowModal(true)
    }

    const onSubmit = (data: CreateNeetingWeekFormData) => {

        const dayWeekGroup = DayWeekEnum[infoUser.group_selected.day_meeting as keyof typeof DayWeekEnum]
        const dayWeek = data.meeting_day.getUTCDay()

        if (dayWeekGroup !== dayWeek) {
            setWeekSelected(getEnumKeyByValue(DayWeekEnum, dayWeek) as string)
            handleOpenModal()
            return
        }

        storeMeetingWeek({
            meeting_day: tranformeValideStringDate(data.meeting_day),
            id_group: data.id_group,
            is_recurrent: data.is_recurrent || false,
            meeting_end: data.meeting_end ? tranformeValideStringDate(data.meeting_end) : undefined
        }).then((response) => {
            if (response) {
                reset()
                getListMeetingWeek()
            }
        })
    }

    const getListMeetingWeek = () => {
        getMeetingWeekList({
            id_group: +groupId!,
            params: searchFactory().createUrlParams({
                page: 1,
                perPage: 10
            })
        }).then((response) => {
            const { data } = response
            setMeetingWeeks(data)
        })
    }

    const handleActionsModal = (action: boolean) => {
        if (!action) {
            handleCloseModal()
            return
        }

        const data = createWeekMeetingForm.getValues()
        const dateString = data.meeting_day?.toString()
        const dateEnd = data.meeting_end?.toString()

        storeMeetingWeek({
            meeting_day: dateString,
            id_group: data.id_group,
            is_recurrent: data.is_recurrent || false,
            meeting_end: data.meeting_end ? dateEnd : undefined
        }).then((response) => {
            if (response) {
                handleCloseModal()
                reset()
                getListMeetingWeek()
            }
        })
    }

    const getListMeetingWeekPagination = (params: IPaginationsCallback) => {
        const paramsUrl = searchFactory().createUrlParams(params)

        getMeetingWeekList({
            id_group: +groupId!,
            params: paramsUrl
        }).then((response) => {
            const { data } = response
            setMeetingWeeks(data)
        })
    }

    useEffect(() => {
        getListMeetingWeek()
    }, [groupId])

    return (
        <>
            <div className="m-2">
                <Container fluid>
                    <Row className="border border-secondary border-2 rounded pt-4">
                        <Col>
                            <Tabs
                                activeKey={activeTab}
                                defaultActiveKey={0}
                                onSelect={(index) => setActiveTab(+index!)}
                                transition={false}
                                className="mb-3"
                            >

                                <Tab eventKey={0} title='Listagem de Reuniões Semanais'>
                                    <ListWeekMeeting
                                        data={meetingWeeks.data}
                                        meta={meetingWeeks.meta}
                                        callbackPagination={getListMeetingWeekPagination}
                                        callbackReaload={getListMeetingWeek}
                                    />
                                </Tab>

                                {CheckPermissionWithArray(['weekly-meetings.store']) && (
                                    <Tab eventKey={1} title='Criar Reunião Semanal'>
                                        <FormProvider {...createWeekMeetingForm}>
                                            <Form onSubmit={handleSubmit(onSubmit)}>
                                                <CreateWeekMeeting goBack={handleGoBack} />
                                            </Form>
                                        </FormProvider>
                                    </Tab>
                                )}
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </div>

            {showModal && (
                <ModalComponent.Root
                    show={showModal}
                    onHide={handleCloseModal}
                    size="lg"
                    className="week_day_modal"
                >
                    <ModalComponent.Content
                        className="week_day_modal_content"
                    >
                        <div className="week_day_modal__title">
                            <p>{t(weekSelected)} {t('weekDefaultMeetingWarning')}</p>
                        </div>

                        <div className="week_day_modal__action">
                            <ModalComponent.Actions
                                variant="primary"
                                className="form-rnd-delete-btn-width"
                                onClick={() => handleActionsModal(true)}
                            >
                                Salvar
                            </ModalComponent.Actions>
                            <ModalComponent.Actions
                                variant="danger"
                                className="form-rnd-delete-btn-width"
                                onClick={() => handleActionsModal(false)}
                            >
                                Cancelar
                            </ModalComponent.Actions>
                        </div>

                    </ModalComponent.Content>
                </ModalComponent.Root>
            )}
        </>
    )
}

export default WeeklyMeetings